import React from 'react'

// Third Party
import parse from 'html-react-parser'
import styled from 'styled-components'

const StyledLessonMethod = styled.div`
  margin-bottom: 50px;
`

const MethodTitle = styled.div`
  text-decoration: underline;
  margin-bottom: 25px;

  @media screen and (max-width: 638px) {
    text-align: center;
  }
`

const MethodContent = styled.div`
  h1 {
    color: ${props => props.theme.color.text.contrast};
    font-family: ${props => props.theme.font.family.third};
    font-size: ${props => props.theme.font.size.ms};
    text-align: center;
    margin-top: 25px;
    margin-bottom: 30px;
  }

  & a {
    color: ${props => props.theme.color.contrast};
    font-weight: ${props => props.theme.font.weight.xl};
    text-decoration: underline;
  }

  @media screen and (max-width: 638px) {
    p {
      text-align: center;
    }
  }
`

const LessonMethod = ({ content }) => {
    return (
      <StyledLessonMethod id="lesson-method" className="show">
        <MethodTitle className="method-title font-size-xm font-weight-xl">Lesmethode</MethodTitle>
        <MethodContent className="method-content font-size-m">
          {parse(content)}
        </MethodContent>
      </StyledLessonMethod>
    )
}

export default LessonMethod