/* eslint-disable no-shadow */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState, useEffect } from 'react'
import StarRatings from 'react-star-ratings'

// Helpers
import API from 'helpers/API'

// Third Party
import parser from 'fast-xml-parser'
import he from 'he'
import styled from 'styled-components'

// Images
import thumbsUp from 'img/review_thumbs_up.svg'

const StyledReviewBox = styled.div`
  width: 300px;
  background-color: ${props => props.theme.color.blue};
  margin-bottom: 40px;
  margin-top: 75px;
  padding-top: 35px;
  min-width: 280px;

  h1 {
    color: ${props => props.theme.color.main};
    text-align: center;
  }

  .star-rating-container {
    .star-ratings {
      margin-bottom: 15px;
    }

    p {
      text-align: center;
    }
  }

  p {
    text-align: center;
  }

  .review-link {
    text-decoration: underline;
  }
`

const ThumbsUp = styled.img`
  position: absolute;
  top: -40px;
`

const ReviewLink = styled.div`
  text-decoration: none !important;

  a {
    border: 1px solid #FFFFFF;
    padding: 8px 10px 8px 10px;
    border-radius: 5px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: #FFFFFF;
    }
  }
`

const Rating = styled.div`
  position: relative;
  left: 25px;
  font-size: ${props => props.theme.font.size.xm};
  
  span {
    font-size: ${props => props.theme.font.size.xxl};
    font-weight: ${props => props.theme.font.weight.xl};
  }
`

const QuoteIcon = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`

function useInterval(callback, delay) {
  const intervalRef = React.useRef();
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(() => callbackRef.current(), delay);

      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
  
  return intervalRef;
}

const ReviewBox = ({ className }) => {

  const [xml, setXml] = useState(null)

  useEffect(() => {
    const getXml = async() => {
      const options = {
        attributeNamePrefix : "@_",
        attrNodeName: "attr",
        textNodeName : "#text",
        ignoreAttributes : true,
        ignoreNameSpace : false,
        allowBooleanAttributes : false,
        parseNodeValue : true,
        parseAttributeValue : false,
        trimValues: true,
        cdataTagName: "__cdata",
        cdataPositionChar: "\\c",
        parseTrueNumberOnly: false,
        arrayMode: false,
        attrValueProcessor: val => he.decode(val, {isAttributeValue: true}),
        tagValueProcessor : val => he.decode(val),
        stopNodes: ["parse-me-as-string"]
      }
      const { data } = await API.xml({url: "https://www.klantenvertellen.nl/v1/review/feed.xml?hash=orclkww0qqg8hln"})
    
      if (data) {
        const tObj = parser.getTraversalObj(data, options);
        const jsonObj = parser.convertToJson(tObj,options);
      
        setXml(jsonObj.ReviewFeedDto)
      }
    }

    getXml()

  }, [])

  const [review, setReview] = React.useState(0);
        
  useInterval(() => {
    if (review < 19) {
      setReview(review + 1);
    } else {
      setReview(0)
    }
  }, 10000);

  return (
    <StyledReviewBox className={`justify-content-center position-relative flex-wrap align-items-center ${className}`}>
      <ThumbsUp src={thumbsUp} alt="" />
      <div>
        <div className="star-rating-container d-flex justify-content-center flex-wrap">
          <div className="pt-5 pb-4">
            <Rating className="mb-4 font-size-xm color-text-light">
              <span className="font-weight-xl">{!xml ? `...` : xml.averageRating}</span>
              {` / 10`}
            </Rating>
            <StarRatings
              rating={!xml ? 0 : xml.averageRating}
              starRatedColor="#FFBB12"
              numberOfStars={5}
              name='rating'
              starDimension="26px"
              starSpacing="0px"
            />
          </div>
        </div>
        <div className="pb-4">
          <QuoteIcon className="color-text-light text-center font-size-xxxl mb-0">&quot;</QuoteIcon>
          <p className="mb-0 pt-3 px-lg-4 px-2 color-text-light">
            {!xml ? `...` : xml.reviews.reviews[review].reviewContent.reviewContent[1].rating}
          </p>
          <p className="w-100 color-text-light font-size-sm">{`- ${!xml ? `...` : xml.reviews.reviews[review].reviewAuthor}`}</p>
          <ReviewLink className="d-flex justify-content-center review-link">
            <a external className="color-text-light" target="_blank" rel="noopener noreferrer" href="https://klantenvertellen.nl/reviews/1061877/Zwemschool%20Snorkeltje">
              {`alle ${!xml ? '...' : xml.numberReviews} reviews >`}
            </a>
          </ReviewLink>
        </div>
      </div>
    </StyledReviewBox>
  )
}

export default ReviewBox