import React from 'react'
import { Link } from 'gatsby';

// Images
import FaqPuppet from 'img/snorkel_wachtlijst_2.svg'

// Component
import CustomLink from 'components/CustomLink'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const StyledButtonContainer = styled.div`
  height: 310px;
  width: 300px;
  background-color: ${props => props.theme.color.main};
  padding: 15px;
  margin-bottom: 40px;

  button {
    width: 250px;
    height: 50px;
    border-radius: 5px;

    .phonenumber {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .faq-image {
    position: absolute;
    right: 0;
    top: -157px;
  }

  .orange {
    background-color: ${props => props.theme.color.contrast};
    color: ${props => props.theme.color.secondary};
    position: relative;
  }

  .white {
    background-color: ${props => props.theme.color.secondary};
    color: ${props => props.theme.color.text.main};
  }
`

const FaqImageOrangeButton = styled.button`
  &::after {
    z-index: 5000;
    content: "Veelgestelde vragen";
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    top: -130px;
    right: 4px;
    height: 100px;
    width: 100px;
    color: ${props => props.theme.color.contrast};
    pointer-events: none;
  }
`

const StyledPuppet = styled.img`
  position: absolute;
  top: -111px;
  left: 10px;
  width: 100px;
  height: 115px;
  z-index: 3;
`

const Bubble = styled(motion.div)`
  background-color: #FFFFFF;

  @media screen and (max-width: 991px) {
    background-color: #f3f3f3;
  }

  z-index: 4;

  ${props => props.width && css`
    width: ${props.width};
  `}

  ${props => props.height && css`
    height: ${props.height};
  `}

  ${props => props.left && css`
    left: ${props.left};
  `}
  
  ${props => props.top && css`
    top: ${props.top};
  `}

  border-radius: 100%;
  position: absolute;
`

const ButtonContainer = ({ inschrijfUrl, className }) => {
  return (
    <StyledButtonContainer className={`mt-5 mt-lg-0 justify-content-center flex-wrap align-items-center ${className}`}>
      <Link to={inschrijfUrl || "/contact"}>
        <FaqImageOrangeButton className="orange" type="button">
          <Link to="/faq">
            <StyledPuppet alt="" src={FaqPuppet} />
            <Bubble top="-80px" left="105px" height="15px" width="15px" animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.3, opacity: 0 }} transition={{ duration: 0.7 }} />
            <Bubble top="-105px" left="115px" height="19px" width="19px" animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.3, opacity: 0 }} transition={{ delay: .3, duration: 0.7 }} />
            <Bubble top="-140px" left="80px" height="37px" width="37px" animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.3, opacity: 0 }} transition={{ delay: .6, duration: 0.7 }} />
            <Bubble top="-165px" left="145px" height="101px" width="101px" animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.3, opacity: 0 }} transition={{ delay: .9, duration: 0.7 }} />
          </Link>
          Inschrijven
        </FaqImageOrangeButton>
      </Link>  
      <CustomLink newPage external to="https://snorkeltje.i-reserve.net/">
        <button className="white" type="button">Reserveren en afmelden</button>
      </CustomLink>
      <Link to="/over-ons">
        <button className="white" type="button">Over ons</button>
      </Link>
      <Link to="/contact">
        <button className="white d-flex align-items-center justify-content-center" type="button">
          <b>Neem contact op</b>
        </button>
      </Link>
    </StyledButtonContainer>
  )
}

export default ButtonContainer