/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const StyledWhy = styled.div`
  margin-bottom: 50px;

  & a {
    color: ${props => props.theme.color.contrast};
    font-weight: ${props => props.theme.font.weight.xl};
    text-decoration: underline;
  }
`

const WhyTitle = styled.div`
  text-decoration: underline;
  margin-bottom: 25px;
`
const Why = ({ content }) => {

  return (
    <StyledWhy id="why-component" className="why-component hide">
      <WhyTitle className="font-size-xm font-weight-xl">Waarom Zwemschool Snorkeltje?</WhyTitle>
      {parse(content.description)}
    </StyledWhy>
  )
}

export default Why