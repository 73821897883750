/* eslint-disable react/no-array-index-key */
import React from 'react'
import Img from 'gatsby-image'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const Employees = ({ object }) => {
  return (
    <div id="employees" className="employees hide">
      <div className="employees-title font-size-xm font-weight-xl">Medewerkers</div>
      <div className="employees-content pt-4 row">
        {object.map((employee, key) => (
          <Employee className="col-md-6 pb-4 pb-md-0 mb-4" employee={employee} key={key} />
        ))}
      </div>
    </div>
  )
}

const StyledImg = styled(Img)`
  border-radius: 100px;
  width: 150px !important;
  height: 150px !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`

const StyledEmployee = styled.div`
  background-color: ${props => props.theme.color.beige};
  
  border-radius: 5px;
  overflow: hidden;

  @media screen and (max-width: 575px) {
    max-height: unset;
  }

  p {
    position: relative;
    top: -15px;
    border-radius: 5px;
    line-height: 15px;
    width: 100%;
    max-width: 180px;

    & a:hover {
      color: #fff;
    }
  }

  h2 {
    font-size: ${props => props.theme.font.size.m};
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  li {
    padding-bottom: 5px;
  }
`

const Employee = ({ employee, className }) => {
  if (employee.medewerkers.length === 0) return null;

  
  return (
    <div className={`px-2 ${className ? `${className}` : ``}`}>
      <StyledEmployee className="p-3 employee h-100">
        <div className="col-12 d-flex justify-content-start flex-wrap">
          <div>
            <StyledImg alt="" fluid={employee.medewerkers[0].acf.photo.localFile.childImageSharp.fluid} />
            <p className="mb-0 px-2 py-2 font-weight-xl color-background-blue color-text-light font-size-s text-center">
              <a href="/over-ons/#employees" className="color-text-light">{employee.medewerkers[0].title}</a>
            </p>
          </div>
        </div>
        <div className="col-12 pr-sm-0">
          <h2 className="mb-3 w-100 text-left mt-1 font-weight-xl">Aanwezig op:</h2>
          {parse(employee.presence)}
        </div>
      </StyledEmployee>
    </div>

  )
}

export default Employees