import React from 'react'
import { Link } from 'gatsby'

// Third Party
import styled from 'styled-components'

const StyledMoreInformation = styled.div`
  .information-title {
    text-decoration: underline;
    margin-bottom: 25px;
  }

  .information-content {
    a {
      color: ${props => props.theme.color.contrast};
    }
  }

  @media screen and (max-width: 638px) {
    .information-title {
      text-align: center;
    }

    .information-content {
      p {
        text-align: center;
      }
    }
  }
`

const MoreInformation = () => {
    return (
      <StyledMoreInformation id="moreInformation" className="hide">
        <div className="information-title font-size-xm font-weight-xl">Meer informatie?</div>
        <div className="information-content font-size-m">
          <p>
            Wil je nóg meer weten over zwemmen bij Zwemschool Snorkeltje? 
            <br />
            Check dan even onze 
            <Link to="/faq"> veelgestelde vragen</Link>
            , misschien staat jouw vraag daar wel tussen. 
            <br />
            Maar je kunt natuurlijk ook via de  
            <Link to="/contact"> contactpagina </Link>
            een berichtje achterlaten.
          </p>
        </div>
      </StyledMoreInformation>
    )
}

export default MoreInformation