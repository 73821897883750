/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React from 'react'
import { graphql } from 'gatsby'
import { forEach, forOwn, sortBy, invert } from 'lodash'
import Img from 'gatsby-image'

// Third Party
import parse from 'html-react-parser'
import styled from 'styled-components'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO';
import LessonMethod from 'components/Location/LessonMethod'
import LessonTimes from 'components/Location/LessonTimes'
import Why from 'components/Location/Why'
import MoreInformation from 'components/Location/MoreInformation'
import ButtonContainer from 'components/Location/ButtonContainer'
import ReviewBox from 'components/Location/ReviewBox'
import ComboInfo from 'components/Location/ComboInfo'
import Employees from 'components/Location/Employees'
import Lesaanbod from 'components/Location/Lesaanbod'
import Modal from 'components/Modal'

const StyledImg = styled(Img)`
  pointer-events: none;
  height: 100%;
  z-index: -1;
`

const ImgContainer = styled.div`
  position: relative;
  height: 330px;

  @media screen and (max-width: 767px) {
    height: 230px;
  }

  @media screen and (max-width: 483px) {
    height: 170px;
  }
`

const ImageContainerText = styled.div`
  margin-top: -100px;
  color: ${props => props.theme.color.text.light};

  @media screen and (max-width: 991px) {
    position: absolute;
    bottom: 5px;
    margin-top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`

const LocationWrapper = styled.div`
  .hide {
    display: none;
  }

  .show {
    display: block;
  }
`

const LocationContainer = styled.div`
  margin-bottom: 150px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 991px) {
    margin-bottom: 40px;
  }
`

const PageFilter = styled.div`
  margin-bottom: 25px;

  ul {
    list-style-type: none;
    padding: 0;
    border-bottom: 1px solid ${props => props.theme.color.contrast};
    transition: 0.25s;

    @media screen and (max-width: 638px) {
      justify-content: center;
    }

    li {
      margin-bottom: 5px;
      transition: 0.25s;

      .selected {
        color: ${props => props.theme.color.secondary};
        background-color: ${props => props.theme.color.contrast};
        transition: 0.25s;
      }

      button {
        color: ${props => props.theme.color.contrast};
        font-weight: ${props => props.theme.font.weight.xl};
        border-radius: 5px;
        transition: 0.25s;
      }
    }
  }
`

const ContentWrapper = styled.div`
  margin-bottom: 50px;

  @media screen and (max-width: 991px) {
    margin-bottom: 0;
  }
`

const Schedule = styled.div`
  background: #FF5C00;
  padding: 1.5rem;
  color: #fff;
  width: 300px;

  @media (max-width: 767px){
    width: 100%;
  }

  table {

    @media (max-width: 991px){
      width: 100%;
    }

    tr {
      display: block;
      padding-bottom: 10px;
      border-bottom: 1px solid #fff;
      margin-bottom: 10px;

      td {
        padding: 5px 0 0;
        display: block;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
`

// Array that indicates the sorting of the days when lessons are available
const sortedDays = invert(["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"])

// Function to change the english names of the days into dutch names
const translateDayName = (day) => {
  switch (day) {
    case 'monday':
      return 'Maandag'
    case 'tuesday':
      return 'Dinsdag'
    case 'wednesday':
      return 'Woensdag'
    case 'thursday':
      return 'Donderdag'
    case 'friday':
      return 'Vrijdag'
    case 'saturday':
      return 'Zaterdag'
    case 'sunday':
      return 'Zondag'
    default:
      return 'Error';
  }
}

export const LocationTemplate = ({
  title,
  slug,
  subTitle,
  featuredImage,
  times,
  timesContent,
  method,
  priceContent,
  prices,
  comboObject,
  employees,
  lesaanbod,
  whyGroup,
  schedule
}) => {
  let currentTab

  const [showMore, setShowMore] = React.useState(false)

  const handleFilter = (filterKey) => {
    const array = [
      { id: 'lesson-method' },
      { id: 'employees' },
      { id: 'why-component' },
      { id: 'times-prices' },
      { id: 'lesaanbod-component' },
      { id: 'moreInformation' }
    ]

    if (currentTab !== filterKey) {
      forEach(array, item => {
        if (item.id !== filterKey) {
          document.getElementById(item.id).classList.add('hide')
          document.getElementById(item.id).classList.remove('show')
          document.getElementById(`${item.id}-tag`).classList.remove('selected')
        } else {
          document.getElementById(filterKey).classList.add('show')
          document.getElementById(`${filterKey}-tag`).classList.add('selected')
        }
      })
      currentTab = filterKey
    } else {
      forEach(array, item => {
        document.getElementById(item.id).classList.add('show')
        document.getElementById(`${item.id}-tag`).classList.remove('selected')
      })
      currentTab = null;
    }
  }

  function formatDate(dateString) {
    // Split the date string into day, month, and year components
    const parts = dateString.split('-');

    // Construct a Date object using the year, month, and day
    const date = new Date(parts[2], parts[1] - 1, parts[0]);

    // Create an array of month names
    const monthNames = [
      "januari", "februari", "maart",
      "april", "mei", "juni", "juli",
      "augustus", "september", "oktober",
      "november", "december"
    ];

    // Get the day, month, and year from the Date object
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Format the date string with the month name
    return `${day} ${monthNames[monthIndex]} ${year}`;
  }

  return (
    <LocationWrapper>
      {title.includes('Breda') && (
        <Modal />
      )}
      {title.includes('Zwijndrecht') && (
        <Modal />
      )}
      <ImgContainer>
        <StyledImg className="d-flex" alt="" fluid={featuredImage.localFile.childImageSharp.fluid} />
        <ImageContainerText className="container">
          <h2 className="font-size-xxl mb-0 color-text-light font-weight-xl pb-1 pb-lg-2">{parse(title)}</h2>
          <h3 className="font-size-l mb-0 d-lg-block d-none font-family-third color-text-contrast">{parse(subTitle)}</h3>
        </ImageContainerText>
      </ImgContainer>
      <LocationContainer className="container">
        <div className="row">
          <div className="col-lg-8 px-lg-5 col-12">
            <ComboInfo object={comboObject} />
            <PageFilter>
              <ul className="d-flex flex-wrap justify-content-center justify-content-lg-between">
                <li>
                  <button id="lesson-method-tag" className="selected" type="button" onClick={() => { handleFilter('lesson-method') }} onKeyPress={() => { handleFilter('lesson-method') }}>
                    Lesmethode
                  </button>
                </li>
                <li>
                  <button id="lesaanbod-component-tag" type="button" onClick={() => { handleFilter('lesaanbod-component') }} onKeyPress={() => { handleFilter('lesaanbod-component') }}>
                    Lesaanbod
                  </button>
                </li>
                <li>
                  <button id="employees-tag" type="button" onClick={() => { handleFilter('employees') }} onKeyPress={() => { handleFilter('employees') }}>
                    Medewerkers
                  </button>
                </li>
                <li>
                  <button id="why-component-tag" type="button" onClick={() => { handleFilter('why-component') }} onKeyPress={() => { handleFilter('why-component') }}>
                    Waarom?
                  </button>
                </li>
                <li>
                  <button id="times-prices-tag" type="button" onClick={() => { handleFilter('times-prices') }} onKeyPress={() => { handleFilter('times-prices') }}>
                    Tijden & Tarieven
                  </button>
                </li>
                <li>
                  <button id="moreInformation-tag" type="button" onClick={() => { handleFilter('moreInformation') }} onKeyPress={() => { handleFilter('moreInformation') }}>
                    Meer informatie
                  </button>
                </li>
              </ul>
            </PageFilter>
            <ContentWrapper id="content-wrapper">
              <LessonMethod content={method} />
              <Lesaanbod content={lesaanbod} />
              <Employees object={employees} />
              <Why content={whyGroup} />
              <LessonTimes url={`/wachtlijst/${slug}`} timesObject={times} timesContent={timesContent} pricesObject={prices} pricesContent={priceContent} />
              <MoreInformation />
            </ContentWrapper>
          </div>
          <div className="col-lg-4">
            <ButtonContainer inschrijfUrl={`/wachtlijst/${slug}`} className="d-none d-lg-flex" />
            {console.log(schedule)}
            {schedule && schedule.length > 0 && (
              <Schedule>
                <h3>Jaarrooster</h3>
                <table>
                  <tbody>
                    {schedule && schedule.length > 0 && schedule.map((item, index) => {

                      // If showMore is false, only show the first 3 items
                      if (index > 4 && !showMore) {
                        return null
                      }

                      const closedTill = formatDate(item.closed_till)

                      const date = new Date(closedTill);
                      const now = new Date();

                      // check if closed till is in the past
                      if (date < now) {
                        return null
                      }

                      return (
                        <tr key={index}>
                          <td>
                            <strong>
                              {item.title}
                              :
                              {' '}
                              {item.closed_from}
                              {' '}
                              t/m
                              {' '}
                              {item.closed_till}
                            </strong>
                          </td>
                          <td>{parse(item.description)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div onClick={() => setShowMore(!showMore)} role="button" rel="noopener noreferrer" className="text-center mt-3">
                  <button type="button" className="btn btn-primary color-background-main border-color-main">
                    {showMore ? 'Bekijk minder' : 'Bekijk meer'}
                  </button>
                </div>
              </Schedule>
            )}
            <ReviewBox className="d-none d-lg-flex" />
          </div>
          {/* <div className="col-lg-4 d-flex mt-5 flex-wrap justify-content-md-between justify-content-center d-lg-none">
            <ButtonContainer inschrijfUrl={`/wachtlijst/${slug}`} className="d-flex d-lg-none" />
            <ReviewBox className="d-flex d-lg-none" />
          </div>    */}
        </div>
      </LocationContainer>
    </LocationWrapper>
  )
}

const Location = ({ data }) => {
  const {
    wordpressWpLocaties: location,
    wordpressPage: {
      acf: {
        phonenumber: phone
      }
    }
  } = data
  const lessonDaysObject = {}
  const pricesObject = {}

  // Formatted String for ComboInfo
  let comboMethodString;
  let comboDayString;
  let comboPriceString;

  // Array to hold all available days
  let comboDaysArray = [];

  // Number to check lowest value of a lesson
  let comboPriceNumber = 1000;

  // Boolean to check when the first iteration has happened to get a nicely formatted limiter between the methods
  let firstMethod = true;
  // Creating "Understandable Data" from the data we get 
  forEach(location.acf.lesson_times.days, (category, key) => {
    const days = []

    let slug = '';

    if (key === 'mini_survival') {

      slug = key.replace(/_/g, "-")

    } else {

      slug = key.substr(10).replace(/_/g, "-")

    }

    forOwn(category, (value, objectKey) => {
      if (value) {
        days.push(translateDayName(objectKey))

        // Get all available days into a Array
        if (!comboDaysArray.includes(translateDayName(objectKey))) {
          comboDaysArray.push(translateDayName(objectKey))
        }
      }
    })

    if (days.length !== 0) {
      // Get all days into a nicely formated string for ComboInfo
      if (firstMethod) {
        comboMethodString = slug;
        firstMethod = false;
      } else {
        comboMethodString = `${comboMethodString} / ${slug}`
      }

      lessonDaysObject[slug] = days
      pricesObject[slug] = location.acf.tarieven.methode[key]
    }
  })



  if (location.acf.additional_lessons.title !== 'empty') {
    forEach(location.acf.additional_lessons, (addition) => {
      forEach(addition.days, (value, objectKey) => {
        const days = []

        const slug = addition.title.substr(0).replace(/_/g, "-")

        if (value) {
          days.push(translateDayName(objectKey))

          // Get all available days into a Array
          if (!comboDaysArray.includes(translateDayName(objectKey))) {
            comboDaysArray.push(translateDayName(objectKey))
          }
        }

        if (days.length !== 0) {
          // Get all days into a nicely formated string for ComboInfo
          if (firstMethod) {
            comboMethodString = slug;
            firstMethod = false;
          } else {
            comboMethodString = `${comboMethodString} / ${slug}`
          }

          lessonDaysObject[slug] = days
          pricesObject[slug] = { per_les: addition.price }
        }
      })
    })
  }

  // Get the cheapest tariff of the available lessons
  forOwn(location.acf.tarieven.methode, (method) => {
    if (method.per_les) {
      const currency = method.per_les.replace(',', '.').replace('-', '00').replace(/[^0-9.-]+/g, "")
      if (currency < comboPriceNumber) {
        comboPriceNumber = currency
        comboPriceString = method.per_les
      }
    }
  })

  // Sort the days array so it makes sense (Monday = first etc. instead of random)
  comboDaysArray = sortBy(comboDaysArray, day => (
    sortedDays[day]
  ))

  // Get all available days into a nicely formatted string for ComboInfo
  // Boolean to check when the first iteration has happened to get a nicely formatted limiter between the days
  let firstDay = true
  forEach(comboDaysArray, (day, index) => {
    if (firstDay) {
      comboDayString = day;
      firstDay = false;
    } else if ((index + 1) !== comboDaysArray.length) {
      comboDayString = `${comboDayString}, ${day}`
    } else {
      comboDayString = `${comboDayString} en ${day}`
    }
  })

  // Create object that is passed to the ComboInfo Component
  const comboObject = {
    method: comboMethodString,
    days: comboDayString,
    times: location.acf.lesson_times.times,
    price: comboPriceString,
    location: {
      address: location.acf.location.information.location_address,
      zipcode: location.acf.location.information.location_zipcode_city,
      number: phone
    }
  }

  return (
    <Layout currentLocation={location.slug}>
      <SEO yoast={{ meta: location.yoastMeta }} pathname={location.path} article />
      <LocationTemplate
        featuredImage={location.acf.image}
        title={location.title}
        slug={location.slug}
        subTitle={location.acf.sub_title}
        timesContent={location.acf.lesson_times.content}
        times={lessonDaysObject}
        method={location.acf.method}
        priceContent={location.acf.tarieven.tariff_info_1}
        prices={pricesObject}
        comboObject={comboObject}
        employees={location.acf.employees}
        lesaanbod={location.acf.lesaanbod}
        whyGroup={location.acf.why_group}
        schedule={location.acf.schedule}
      />
    </Layout>
  )
}

export default Location

export const pageQuery = graphql`
  fragment PostFields on wordpress__wp_locaties {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query DetailLocatieQuery($id: String!) {
    wordpressPage(wordpress_id: {eq: 50}) {
      acf {
        phonenumber
      }
    }
    wordpressWpLocaties(id: {eq: $id}) {
      id
      title
      slug
      path
      acf {
        image {
          source_url
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        location_title
        sub_title
        location {
          information {
            location_address
            location_zipcode_city
          }
        }
        additional_lessons {
          title
          days {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
          }
          price
        }
        lesson_times {
          content
          days {
            wordpress_1_op_1 {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            } 
            wordpress_1_op_2 {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }
            wordpress_1_op_3 {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            } 
            wordpress_1_op_4 {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            } 
            wordpress_1_op_5 {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }  
            wordpress_1_op_6 {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }
            mini_survival {
              wednesday
              tuesday
              thursday
              sunday
              monday
              friday
              saturday
            }
          }
          times
        }
        lesaanbod {
          description
        }
        method
        why_group {
          description
        }
        tarieven {
          tariff_info_1 {
            text_2
          }
          methode {
            wordpress_1_op_1 {
              per_les
            }
            wordpress_1_op_2 {
              per_les
            }
            wordpress_1_op_3 {
              per_les
            }
            wordpress_1_op_4 {
              per_les
            }
            wordpress_1_op_5 {
              per_les
            }
            wordpress_1_op_6 {
              per_les
            }
            mini_survival {
              per_les
            }
          }
        }
        employees {
          medewerkers {
            title
            wordpress_id
            acf {
              quote
              photo {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          presence
        }
        # schedule {
        #   title
        #   closed_from
        #   closed_till
        #   description
        # }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`
