import React from 'react'

// Images
import swimming from 'img/swim.svg'
import calendar from 'img/calendar.svg'
import clock from 'img/clock.svg'
import price from 'img/price.svg'
import swimStair from 'img/swim_stair.svg'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'

const Icon = styled.img`
  height: 30px;

  ${props => props.small && css`
    height: 25px;
  `}
`

const ImageContainer = styled.div``

const StyledComboInfo = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;

  @media screen and (max-width: 991px) {
    padding-top: 10px;
  }
`

const TimesContent = styled.div`
  p {
    margin-bottom: 0;
  }
`

const ComboInfo = ({ object }) => {
  return (
    <StyledComboInfo>
      <div className="row">
        <div className="col-sm-6">
          <div className="combined row py-3 pr-4">
            <div className="col-3 justify-content-center align-items-center">
              <ImageContainer className="img-container d-flex justify-content-center align-items-center w-10">
                <Icon small alt="" src={swimming} />
              </ImageContainer>
            </div>
            <div className="col-9">
              <p className="mb-0 w-100 text-left d-flex justify-content-start align-items-center text-capitalize">
                {object.method}
              </p>
            </div>
          </div>
          <div className="combined row py-3 pr-4">
            <div className="col-3 d-flex justify-content-center align-items-center">
              <div className="img-container d-flex justify-content-center align-items-center">
                <Icon alt="" src={calendar} />
              </div>
            </div>
            <div className="col-9">
              <p className="mb-0 w-100 text-left d-flex justify-content-start align-items-center">
                {object.days}
              </p>
            </div>
          </div>
          <div className="combined row py-3 pr-4">
            <div className="col-3 justify-content-center align-items-center">
              <div className="img-container d-flex justify-content-center align-items-center w-10">
                <Icon alt="" src={clock} />
              </div>
            </div>
            <div className="col-9">
              <TimesContent className="mb-0 w-80 d-flex text-right justify-content-start align-items-center">
                {parse(object.times)}
              </TimesContent>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="combined row py-3 pr-4">
            <div className="col-3 d-flex justify-content-center align-items-center">
              <div className="img-container d-flex justify-content-center align-items-center w-10">
                <Icon alt="" src={price} />
              </div>
            </div>
            <div className="col-9">
              <p className="mb-0 w-80 d-flex justify-content-start align-items-center">
                vanaf
                {' '}
                {object.price}
                {' '}
                per les
              </p>
            </div>
          </div>
          <div className="combined row py-3 pr-4">
            <div className="col-3 d-flex justify-content-center align-items-center">
              <div className="img-container d-flex justify-content-center align-items-start w-10">
                <Icon alt="" src={swimStair} />
              </div>
            </div>
            <div className="col-9">
              <p className="mb-0 w-80 text-left d-flex justify-content-start align-items-center">
                {object.location.address}
                <br />
                {object.location.zipcode}
                <br />
                {object.location.number}
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledComboInfo>
  )
}

export default ComboInfo