import React from 'react'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const StyledAgenda = styled.div`
  margin-bottom: 50px;
`

const AgendaTitle = styled.div`
  text-decoration: underline;
  margin-bottom: 25px;
`

const AgendaContent = styled.div`
  h1 {
    color: ${props => props.theme.color.contrast};
    font-family: ${props => props.theme.font.family.secondary};
    font-size: 18px;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 30px;
  }
`

const Lesaanbod = ({ content }) => {
  return (
    <StyledAgenda id="lesaanbod-component" className="hide">
      <AgendaTitle className="font-size-xm font-weight-xl">Lesaanbod</AgendaTitle>
      <AgendaContent className="font-size-m">
        <div>
          {parse(content.description)}
        </div>
      </AgendaContent>
    </StyledAgenda>
  )
}

export default Lesaanbod