/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'gatsby'

// Third Party
import { map } from 'lodash'
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'

const StyledLink = styled(Link)`
  background-color: ${props => props.theme.color.contrast};
  height: 50px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: .25s;

  &:hover {
    transform: scale(1.05);
  }

  p {
    color: ${props => props.theme.color.text.light};
    font-weight: ${props => props.theme.font.weight.xl};
  }
`

const TimesPrices = styled.div`
  margin-bottom: 50px;
`

const TimesTitle = styled.div`
  text-decoration: underline;
  margin-bottom: 25px;

  @media screen and (max-width: 638px) {
    text-align: center;
  }
`

const TimesContent = styled.div`
  p {
    margin: 0;

    @media screen and (max-width: 638px) {
      text-align: center;
    }
  }

  a {
    color: ${props => props.theme.color.contrast};
  } 
`

const TimesContainer = styled.div`
  @media screen and (max-width: 638px) {
    justify-content: center !important;
  }

  @media screen and (max-width: 590px) {
    .combined {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
    }
  }
`

const TimesButton = styled.div`
  button {
    background-color: ${props => props.theme.color.contrast};
    height: 50px;
    width: 225px;
    border-radius: 5px;
    transition: 0.25s;

    a {
      color: ${props => props.theme.color.secondary};
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 638px) {
    width: 100% !important;
    margin-top: 30px;
  }
`

const TimesContainerMethod = styled.div`
  @media screen and (max-width: 638px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`

const PricesContainer = styled.div`
  background-color: ${props => props.theme.color.beige};
  border-radius: 5px;
  margin-bottom: 30px;
  row-gap: 30px;

  @media screen and (max-width: 590px) {
    justify-content: center !important;
    .combined {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
`

const PricesContent = styled.div`
  @media screen and (max-width: 638px) {
    p {
      text-align: center;
    }
  }
`

const PricesContainerMethod = styled.div`
  margin-right: 50px;

  ${props => props.smallView && css`
    display: none;

    @media screen and (max-width: 590px) {
      display: block;
    }
  `}

  ${props => props.bigView && css`
    @media screen and (max-width: 590px) {
      display: none;
    }
  `}

  @media screen and (max-width: 670px) {
    margin-right: 30px;

    .ellip {
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`

const TimesContainerRow = styled.div`
  row-gap: 1rem;
`

const LessonTimes = ({ url, timesObject, timesContent, pricesObject, pricesContent }) => {
  return (
    <TimesPrices id="times-prices" className="times-prices hide">
      <TimesTitle className="font-size-xm font-weight-xl">Tijden & Tarieven</TimesTitle>
      <TimesContent className="font-size-m" dangerouslySetInnerHTML={{ __html: timesContent }} />
      <TimesContainer className="row mt-5 mb-4 font-size-m">
        <div className="col-lg-8">
          <TimesContainerRow className="row">
            {map(timesObject, (value, key) => (
              <div key={key} className="col-6 col-md-4 combined">
                <TimesContainerMethod key={key} className="times-container-method">
                  <div className="font-weight-l text-capitalize">{`${key} zwemles`}</div>
                  {map(value, (lowerValue, lowerKey) => (
                    <div key={lowerKey} className="">{lowerValue}</div>
                  ))}
                </TimesContainerMethod>
              </div>
            ))}
          </TimesContainerRow>
        </div>
        <div className="col-lg-4">
          <TimesButton className="times-button d-flex justify-content-end align-items-center font-size-m">
            <StyledLink to={url || "/wachtlijst"}>
              <p className="mb-0">Inschrijven</p>
            </StyledLink>
          </TimesButton>
        </div>
      </TimesContainer>
      <PricesContainer className="p-4 font-size-m flex-wrap d-flex justify-content-start">
        <PricesContainerMethod bigView>
          <div className="font-weight-l color-text-secondary"><br /></div>
          <div className="font-weight-l">Per les</div>
        </PricesContainerMethod>
        {map(pricesObject, (value, key) => (
          <div key={key} className="combined">
            <PricesContainerMethod smallView>
              <div className="font-weight-l color-text-secondary"><br /></div>
              <div className="font-weight-l">Per les</div>
            </PricesContainerMethod>
            <PricesContainerMethod key={key}>
              <div className="ellip font-weight-l text-capitalize">{`${key} zwemles`}</div>
              {map(value, (lowerValue, lowerKey) => (
                <div key={lowerKey} className="">{lowerValue}</div>
              ))}
            </PricesContainerMethod>
          </div>
        ))}
      </PricesContainer>
      <PricesContent className="font-size-m">
        <div className="mt-4">
          {parse(pricesContent.text_2)}
        </div>
      </PricesContent>
    </TimesPrices>
  )
}

export default LessonTimes